import * as React from 'react'
import SmallBanner from '../components/SmallBanner'
import Container from '../components/Container'
import styled from 'styled-components'
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { breakpoint, colors } from '../styles/variables'
import { Link } from 'gatsby'


const Wrapper = styled(Container)`
  min-height: 100vh;
  margin-top: 2rem;
  margin-bottom: 3rem;
  max-width: 700px;
  padding: 0 1rem 2rem;

  @media ${breakpoint.sm} {
    margin-top: 3rem;
  }

  @media ${breakpoint.md} {
    margin-top: 4.5rem;
    margin-bottom: 5rem;
  }

  @media ${breakpoint.lg} {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
`

const Title = styled.h2 `
    margin-top: 0;
    text-transform: none;
    margin-bottom: 0.5em;
    font-size: 1.75rem;
`

const StyledFigure = styled.figure `
    margin: 1.5rem 0 2rem;
    display: grid;
    justify-content: center;

    /* img {
         max-height: 440px;
    } */

   /*  ${({doubleImage}) => `${doubleImage && `
        @media ${breakpoint.sm} {
            grid-template-rows: 440px auto;
        }
    `}`} */



    @media ${breakpoint.md} {
        margin: 2rem 0 2.5rem;
    }

   /*  img {
        max-height: 440px;
        width: auto;
    } */
`

const StyledFigcaption = styled.figcaption `
    font-size: 0.875rem;
    color: gray;
    margin-top: 0.5em;
    text-align: right;
`

const BackLink = styled(Link)`
  text-decoration: none;
  color: ${colors.primary};
  margin-bottom: 2.5rem;
  display: inline-block;
  font-size: 0.875rem;
  transition: color 0.3s ease;

  @media ${breakpoint.lg} {
    margin-bottom: 4rem;
  }

  &:hover {
    color: ${colors.orangeHover};
  }

  .icon {
    margin-right: 0.5rem;
  }
`

const Date = styled.p`
    color: gray;
    margin: 0;
    font-size: 0.875rem;
`

const BlogList = styled.ol `
     padding: 0;
     list-style: none;
    counter-reset: my-awesome-counter;

    h3 {
        font-size: 1rem;
        padding-left: 1.25em;
        margin-bottom: 0.5em;
        font-weight: 700;
    }
    p {
        display: inline;
    }

    li {
        margin: 1.5rem 0;
        line-height: 1.5;
        counter-increment: my-awesome-counter2;
        position: relative;

        &::before {
            content: counter(my-awesome-counter2) "." ;
            font-weight: 700;
            position: absolute;
        }

        ul li::before {
            content: normal;
        }
    }
`
const DoubleImage = styled.div `
    margin: 1.5rem 0 2.5rem;

    @media ${breakpoint.sm} {
        display: grid;
     gap: 0.5rem;
        grid-template-columns: repeat(2, 1fr);
    }
`

const Author = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 0.875rem;
  opacity: 0.75;
`

const Info = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin: 0.3rem 0 0.5rem;
  margin-bottom: 2rem;
`

const BlogpostPage = ({data, title}) => {

    return (
       <>
        <SmallBanner title="Blog"/>
        <Wrapper>
            <BackLink to="/blog">
                <span className="icon icon-arrow-left"></span>
                Zpět na výpis článků
            </BackLink>
            <Title>Barcelona</Title>
            <Info>
                <Author>Simona</Author>
                <Date>12. 6. 2023</Date>
            </Info>
            <p>Poslední únorový víkend v roce 2022 jsem se vydala prozkoumat Barcelonu. Nebyla to moje první návštěva tohoto města, ale vzhledem k tomu, že poprvé jsem tady byla na jeden den před 12 lety a navíc ještě pod taktovkou CK, vzpomínky byly velmi matné. A tak jsem si chtěla vytvořit nové. Jak se mi to povedlo?</p>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/barcelona/barcelona1.jpeg" alt=""></StaticImage>
                <StyledFigcaption>Plaça Reial, Barcelona</StyledFigcaption>
            </StyledFigure>
            <p>Na koupání to úplně nebylo, co Vám budu povídat. Ale i tak bylo počasí kolem 15°C, což ve srovnání s počasím v Praze, znamenalo rozhodně teplo. (ty nohy v moři jsem si stejně smočila) Do Barcelony jsem letěla přímým letem z Prahy 2h20min. Z letiště El Prat existuje <a href="https://mesto-barcelona.cz/doprava/doprava-z-letiste/">několik možností</a>, jak se dostat do centra, já zvolila Aerobus za 5,90 €, který mě odvezl až na Plaça de Catalunya, odkud jsem to měla pár minut pěšky do <a href="https://www.booking.com/hotel/es/bed-and-bike-barcelona.cs.html?aid=376392&sid=34856f1f12e3ca90a77249377c5ab247&dest_id=-372490;dest_type=city;dist=0;group_adults=2;group_children=0;hapos=1;hpos=1;no_rooms=1;req_adults=2;req_children=0;room1=A%2CA;sb_price_type=total;sr_order=popularity;srepoch=1673199255;srpvid=c3507b8b90b901a1;type=total;ucfs=1&#hotelTmpl">hostelu</a>.</p>
            <p>V Barceloně jsem strávila celkem 4 dny, první den jsem se šla jen tak projít a téměr obešla celé centrum. Začala jsem u <a href="https://www.google.com/maps/place/Arc+de+Triomf,+08018+Barcelona,+%C5%A0pan%C4%9Blsko/@41.391037,2.1784675,17z/data=!3m1!4b1!4m5!3m4!1s0x12a4a2e33620cac5:0x2caf25a2625951be!8m2!3d41.391033!4d2.1806615">Arc de Triomf</a>, pokračovala přes Parc de la Cituadella, kde je běžné udělat si piknik, prohlédnout katalánský parlament nebo pokračovat do místní Zoo. Já ale zamířila přes <a href="https://www.google.com/maps/place/Parc+del+Port+Ol%C3%ADmpic/@41.3862864,2.191066,16z/data=!4m12!1m6!3m5!1s0x12a4a30cc56ab225:0xa72f9e3ccdb85269!2sPort+Ol%C3%ADmpic!8m2!3d41.3879164!4d2.1994935!3m4!1s0x12a4a30df983b569:0x6c45b5c435b2754e!8m2!3d41.389112!4d2.1988923">Parc del Port Olímpic</a> k soše Zlaté ryby (v katalánštině Peix Daurat) od kanadského architekta Franka Gehryho, která vznikla na počest pořádání Olympijských her v roce 1992 až na pláž <a href="https://www.google.com/maps/place/Barceloneta+Beach/@41.3801428,2.1868437,15z/data=!4m5!3m4!1s0x12a4a3a809389627:0x1e8e0ed73f4965fb!8m2!3d41.3783713!4d2.1924685">Platja de la Barceloneta</a>.</p>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/barcelona/barcelona2.jpeg" alt=""></StaticImage>
                <StyledFigcaption>La Barceloneta</StyledFigcaption>
            </StyledFigure>
            <p>Na druhý den ráno jsem měla předem koupenou <a href="https://www.tiqets.com/en/barcelona-attractions-c66342/tickets-for-sagrada-familia-fast-track-p918256/">vstupenku</a> do Sagrada Familia. Vstup do věží byl v té době kvůli covidovým opatřením ještě zavřený, takže za téměř 34 € jsem si prošla hlavní prostor katedrály od východu na západ (to poznáte podle barevných vitráží v oknech katedrály), ze severu na jih a během hodiny a půl jsem byla zase venku. Ke vstupence jsem si stáhla (anglického) audio průvodce formou aplikace a zaposlouchala se do informací. Nabitý mobil a sluchátka jsou důležitou součástí výbavy správného turisty!</p>
            <DoubleImage>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/barcelona/barcelona3.jpeg" alt=""></StaticImage>
                    <StyledFigcaption>Sagrada Familia</StyledFigcaption>
                </StyledFigure>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/barcelona/barcelona4.jpeg" alt=""></StaticImage>
                    <StyledFigcaption>Sagrada Familia</StyledFigcaption>
                </StyledFigure>
            </DoubleImage>
            <p>Po prohlídce katedrály jsem měla sraz s Alenn, se kterou jsem se seznámila v hostelu a společně jsme vyrazily metrem na Montjuic. Slavné fontány vzhledem k ročnímu období zely prázdnotou, a tak slavný <a href="https://www.youtube.com/watch?v=Y1fiOJDXA-E">song</a> natočený rovněž k příležitosti pořádání Olympijských her v roce 1992 od Freddieho Mercuryho a Monsterat Caballé jsme si symbolicky pustily přes youtube a krásu fontán si jen představovaly.  Po vyjití schodů jsme si už jen užívaly výhled a živou hudbu, kterou zajišťoval místní postarší pán. Pak už jsme prošly jen olympijský park Anella Olympica a vydaly se zpět brázdit ulice centra a dát si churros.</p>
            <p>Malý tip: na Montjuicu se nachází <a href="https://www.museunacional.cat/en/opening-hours-and-prices">Národní muzeum katalánského umění</a>, kde soboty a první neděle v měsíci jsou po 15. hodině zdarma. Večer jsme se znovu vrátily podívat se na osvětlenou Sagradu Familii, projít město a zažít noční La Ramblu.</p>
            <p>Večer jsme se znovu vrátily podívat se na osvětlenou Sagradu Familii, projít město a zažít noční La Ramblu.</p>
            <p>Ráno jsem se opět vydala po stopách Gaudího, tentokrát do <a href="https://www.parkguell-tickets.com/?gclid=CjwKCAiA8OmdBhAgEiwAShr409_qlQ1XqYgEiToT87nDvUH-uOj8Kix9LZBzbxj9gJdnMRdlja2VxBoCdKAQAvD_BwE">Park Güell</a>. Kousek od hostelu z autobusové zastávky <a href="https://www.google.com/maps/place/Pg+de+Gr%C3%A0cia+-+Arag%C3%B3/@41.3917818,2.1648495,19z/data=!4m12!1m6!3m5!1s0x12a4a2ed494b4161:0x40d2782f9e2e4e0f!2sCasa+Batll%C3%B3!8m2!3d41.3917281!4d2.1649487!3m4!1s0x12a4a2ed441de0e3:0xbbd80f8269af4aae!8m2!3d41.3917978!4d2.1655409">Pg de Grácia - Aragó</a> jezdí přímé linky č. 22 a 24 a cesta trvá cca 30 minut. Při čekání na autobus jsem obdivovala <a href="https://www.google.com/maps/place/Casa+Batll%C3%B3/@41.3916088,2.1625483,17z/data=!3m1!4b1!4m5!3m4!1s0x12a4a2ed494b4161:0x40d2782f9e2e4e0f!8m2!3d41.3917281!4d2.1649487">Casa Batló</a> a cestou, po pravé straně jsme míjeli i <a href="https://www.google.com/maps/place/Casa+Mil%C3%A0/@41.3954586,2.1589944,16.99z/data=!3m1!5s0x12a4a2938498d7d1:0x46144f3c1fee22fb!4m22!1m16!4m15!1m6!1m2!1s0x12a4a2ed441de0e3:0xbbd80f8269af4aae!2sPg+de+Gr%C3%A0cia+-+Arag%C3%B3!2m2!1d2.1655409!2d41.3917978!1m6!1m2!1s0x12a4a2ae52d441ab:0x899a0ba01aaace58!2zUGFyayBHw7xlbGwsIDA4MDI0IEJhcmNlbG9uYSwgxaBwYW7Em2xza28!2m2!1d2.1526945!2d41.4144948!3e3!3m4!1s0x0:0xc49fa3624f371e6a!8m2!3d41.3952155!4d2.1619024">Casa Milà</a>.</p>
            <StyledFigure>
                    <StaticImage src="../assets/images/blog/barcelona/barcelona5.jpeg" alt=""></StaticImage>
                    <StyledFigcaption>Sagrada Familia</StyledFigcaption>
                </StyledFigure>
            <p>Odpoledne jsme strávily v <a href="https://www.google.com/maps/place/Casa+Jaume+1913/@41.3802381,2.1859716,17z/data=!4m5!3m4!1s0x12a4a3e8c4555897:0x92230130755758c!8m2!3d41.3808789!4d2.1872872">restauraci</a>, kde jsme si daly sangrii a paellu pro tři (a i tak jsme ji ve třech nesnědly). A pak už jen do západu slunce užívaly relaxu na pláži, jako místní.</p>
            <p>V den odletu jsem ještě stihla projít Barri Gótic, kde se nachází La Catedral, zastavit se v Mercat de la Boqueria a pak dojít až na zastávku <a href="https://www.google.com/maps/place/Pl.+Espanya/@41.3748306,2.1484366,19z/data=!4m5!3m4!1s0x12a4a279108e12c1:0xe90891c19b42a499!8m2!3d41.3744681!4d2.1480636">Plaça d'Espaya</a>. Já jsem měla dost času a tak mi nevadilo dojít až sem, z této zastávky jsem totiž jela na letiště městským autobusem č. 46, s nímž jedna cesta stojí 2,40 €. Já jsem ale využila ještě volných jízd na své jízdence T-casual, která má v sobě 10 jízd za cenu 11,35 € a je možné ji využít na MHD po Barceloně.</p>
        </Wrapper>
       </>
    )
  }

  export default BlogpostPage
